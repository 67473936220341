import React from 'react'

import theme from '../../lib/theme'

import SocialSvg from './SocialSvg'

const FacebookIcon = ({
  width = theme.spacing.x4,
  color = theme.colors.secondary,
}) => (
  <SocialSvg
    width={width}
    viewBox="0 0 448 448"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M448 48v352c0 26.5-21.5 48-48 48h-85.3V270.8h60.6l8.7-67.6h-69.3V160c0-19.6 5.4-32.9 33.5-32.9H384V66.7c-6.2-.8-27.4-2.7-52.2-2.7-51.6 0-87 31.5-87 89.4v49.9H184v67.6h60.9V448H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h352c26.5 0 48 21.5 48 48z"
      fill={color}
      fillRule="nonzero"
    />
  </SocialSvg>
)

export default FacebookIcon
