import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { differenceInDays } from 'date-fns'
import theme from '../lib/theme'
import {
  getCompetitionsReq,
  setCompetition,
  getEntriesReq,
  clearMetric,
} from '../redux/Metrics/MetricsActions'
import AthleteSelector from '../components/AthleteSelector'
import Metrics from '../components/Metrics'
import VenueMetrics from '../components/VenueMetrics'
import { Row, Col } from '../components/Grid'
import Spacer from '../components/Spacer'
import Text from '../components/Text'
import ScreenContainer from '../components/ScreenContainer'
import BlogPost from '../components/BlogPost'
import PCWidget from '../components/PCWidget'
import FailedAccess from '../components/FailedAccess'
import Dropdown from '../components/Dropdown'

const Home = ({
  selectedAthlete,
  personalBests,
  selectedComp,
  riderMetrics,
  horseMetrics,
  riderResults,
  horseResults,
  timeOfLogin,
  competitions,
  getCompetitionsReq,
  activeCompetition,
  setCompetition,
  getEntriesReq,
  clearMetric,
}) => {
  const getData = async () => {
    await getCompetitionsReq()
  }
  const setCompetitionGetEntries = async competition => {
    setCompetition(competition)
    clearMetric()
    getEntriesReq(competition.compSourceId)

    let clearMetricButton = document.getElementById('clearMetric')
    if (clearMetricButton) {clearMetricButton.click()}
  }

  useEffect(() => {
    getData()
  }, [])

  return competitions ? (
    <ScreenContainer>
      <Spacer size={theme.spacing.x4} />{' '}
      <>
        <Row>
          <Col size={[1, 1 / 2]}>
            <Dropdown
              items={competitions}
              onChange={comp => setCompetitionGetEntries(comp)}
              fontSize={theme.fontScale.x5}
              itemToString={comp => comp.competitionName || null}
              placeholder="Choose a competition"
              initialItem={competitions[0]}
              handleFilter={() => () => true}
              readOnly={true}
              displaySuggestion={item => (
                <>
                  <Spacer size={theme.spacing.x3} />
                  <Text
                    tag="h1"
                    size={[theme.fontScale.x4]}
                    weight={theme.fontWeights.bold}
                    lineHeight={theme.lineHeights.heading}
                  >
                    {item.competitionName}
                  </Text>
                  <Spacer size={theme.spacing.x3} />
                </>
              )}
            />
          </Col>
          <Col size={[1, 1 / 2]}>
            <AthleteSelector />
          </Col>
        </Row>
      </>
      {selectedAthlete ? (
        <Metrics
          competition={selectedComp}
          athlete={selectedAthlete}
          personalBests={personalBests}
          riderMetrics={riderMetrics}
          horseMetrics={horseMetrics}
          riderResults={riderResults}
          horseResults={horseResults}
        />
      ) : (
        <>
          {selectedComp && <VenueMetrics selectedComp={selectedComp} />}
          <Spacer size={theme.spacing.x3} />
          {selectedComp && selectedComp.articles && (
            <>
              <Row>
                <Col size={[1, 1 / 4]}>
                  <Col size={[1 / 2, 1]}>
                    <BlogPost
                      url={selectedComp.articles.article_1.url}
                      img={selectedComp.articles.article_1.image_url}
                      title={selectedComp.articles.article_1.title}
                      author={selectedComp.articles.article_1.author}
                    />
                  </Col>
                  <Col size={[1 / 2, 1]}>
                    <BlogPost
                      url={selectedComp.articles.article_2.url}
                      img={selectedComp.articles.article_2.image_url}
                      title={selectedComp.articles.article_2.title}
                      author={selectedComp.articles.article_2.author}
                    />
                  </Col>
                </Col>
                <Col size={[1, 3 / 4]}>
                  <PCWidget compSourceId={selectedComp.compSourceId} />
                </Col>
              </Row>
            </>
          )}
          <Spacer size={theme.spacing.x5} />{' '}
        </>
      )}
    </ScreenContainer>
  ) : null
}

export default connect(
  ({ MetricsState, UserState }) => ({
    selectedComp: MetricsState.selectedComp,
    selectedAthlete: MetricsState.selectedAthlete,
    personalBests: MetricsState.personalBests,
    riderMetrics: MetricsState.riderMetrics,
    horseMetrics: MetricsState.horseMetrics,
    riderResults: MetricsState.riderResults,
    horseResults: MetricsState.horseResults,
    timeOfLogin: UserState.timeOfLogin,
    competitions: MetricsState.competitions,
    activeCompetition: UserState.activeCompetition,
  }),
  { getCompetitionsReq, setCompetition, getEntriesReq, clearMetric }
)(Home)
