import React from 'react'

import Section from './Section'

const PCWidget = ({ compSourceId }) => {
  return compSourceId ? (
    <>
      <Section>
        <iframe
          title="Predictive centre widget"
          src={`https://prediction-centre.equiratings.com/competitions/${compSourceId}?embed=true`}
          width={'100%'}
          height={'980px'}
          frameBorder={0}
        />
      </Section>
    </>
  ) : null
}

export default PCWidget
