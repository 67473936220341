import axios from 'axios'
import { store } from '../redux/store'
import { format, subYears, subMonths } from 'date-fns'

const currentDate = format(Date(), 'YYYY-MM-DD')
// const fourDaysAgo = format(subDays(currentDate, 4), 'YYYY-MM-DD')
const tenYearsAgo = format(subYears(currentDate, 10), 'YYYY-MM-DD')
const twelveMonthsAgo = format(subMonths(currentDate, 12), 'YYYY-MM-DD')

const instance = () => {
  const API_HOST_URL = process.env.REACT_APP_API_HOST_URL
  const options = {
    baseURL: `${API_HOST_URL}/dashboard/media`,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  const accessToken = store.getState().UserState.accessToken;
  if (accessToken) {
    options.headers = {
      Authorization: `Bearer ${accessToken}`,
    }
  }

  const client = axios.create(options)
  return client
}

export const getCompetitions = () => {
  return instance().get(
    `/dashboard_media_competitions?date_from=${tenYearsAgo}`
  )
}

export const getEntries = compSourceId => {
  return instance().get(`/dashboard_media_competition_entries/${compSourceId}`)
}

export const riderStatsByDate = atheleteId => {
  return instance().get('/dashboard_media_metrics', {
    params: {
      athlete_id: atheleteId,
      date_from: twelveMonthsAgo,
    },
  })
}

export const riderStatsByLevel = (erLevel, athleteId) => {
  return instance().get('/dashboard_media_metrics', {
    params: {
      athlete_id: athleteId,
      er_level: erLevel,
    },
  })
}

export const riderStatsAtComp = (erLevel, venueId, athleteId) => {
  return instance().get('/dashboard_media_metrics', {
    params: {
      athlete_id: athleteId,
      er_level: erLevel,
      venue_id: venueId,
    },
  })
}

export const horseStatsByDate = horseId => {
  return instance().get('/dashboard_media_metrics', {
    params: {
      horse_id: horseId,
      date_from: twelveMonthsAgo,
    },
  })
}

export const horseStatsByLevel = (erLevel, horseId) => {
  return instance().get('/dashboard_media_metrics', {
    params: {
      horse_id: horseId,
      er_level: erLevel,
    },
  })
}

export const horseStatsAtComp = (erLevel, venueId, horseId) => {
  return instance().get('/dashboard_media_metrics', {
    params: {
      horse_id: horseId,
      er_level: erLevel,
      venue_id: venueId,
    },
  })
}

export const getPersonalBests = (compSourceId, athleteId, horseId) => {
  return instance().get('/dashboard_media_pbs', {
    params: {
      upcoming_competition_source_id: compSourceId,
      athlete_id: athleteId,
      horse_id: horseId,
    },
  })
}

export const getRiderResultsMr = athleteId => {
  return instance().get('/dashboard_results', {
    params: {
      athlete_id: athleteId,
      major_results: 3
    },
  })
}

export const getRiderResultsCf = athleteId => {
  return instance().get('/dashboard_results', {
    params: {
      athlete_id: athleteId,
      current_form: 8,  
    },
  })
}

export const getHorseResultsMr = horseId => {
  return instance().get('/dashboard_results', {
    params: {
      horse_id: horseId,
      major_results: 3
    },
  })
}

export const getHorseResultsCf = horseId => {
  return instance().get('/dashboard_results', {
    params: {
      horse_id: horseId,
      current_form: 8,
    },
  })
}
