import styled from 'styled-components'

const SocialSvg = styled.svg`
  opacity: 0.5;
  transition: opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1),
    transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transform: scale(0.95);
  width: ${({ width }) => width};
  &:hover {
    opacity: 1;
    transform: scale(1.2);
  }
`

export default SocialSvg
