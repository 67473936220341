import React, { Fragment } from 'react'
import styled from 'styled-components'

import theme from '../lib/theme'

import { Row, Col } from '../components/Grid'
import Spacer from '../components/Spacer'
import Section from '../components/Section'
import PersonalBest from '../components/PersonalBest'
import Text from '../components/Text'

const MetricsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Metric = styled.div`
  position: relative;
  top: 1px;
`

const Year = styled.div`
  align-self: flex-end;
  flex: 1;
  position: relative;
  bottom: 2px;
`

const VenueMetrics = selectedComp => {
  const compMetrics = selectedComp.selectedComp.competitionMetrics
  console.log(selectedComp)
  const FirstSet = [
    {
      metric: compMetrics && compMetrics.metric_1.title,
      desc1: compMetrics && compMetrics.metric_1.description_1,
      desc2: compMetrics && compMetrics.metric_1.description_2,
      score: compMetrics && compMetrics.metric_1.value,
      year: compMetrics && compMetrics.metric_1.date,
    },
    {
      metric: compMetrics && compMetrics.metric_2.title,
      desc1: compMetrics && compMetrics.metric_2.description_1,
      desc2: compMetrics && compMetrics.metric_2.description_2,
      score: compMetrics && compMetrics.metric_2.value,
      year: compMetrics && compMetrics.metric_2.date,
    },
    {
      metric: compMetrics && compMetrics.metric_3.title,
      desc1: compMetrics && compMetrics.metric_3.description_1,
      desc2: compMetrics && compMetrics.metric_3.description_2,
      score: compMetrics && compMetrics.metric_3.value,
      year: compMetrics && compMetrics.metric_3.date,
    },
  ]
  const SecondSet = [
    {
      metric: compMetrics && compMetrics.metric_4.title,
      desc1: compMetrics && compMetrics.metric_4.description_1,
      desc2: compMetrics && compMetrics.metric_4.description_2,
      score: compMetrics && compMetrics.metric_4.value,
      year: compMetrics && compMetrics.metric_4.date,
    },
    {
      metric: compMetrics && compMetrics.metric_5.title,
      desc1: compMetrics && compMetrics.metric_5.description_1,
      desc2: compMetrics && compMetrics.metric_5.description_2,
      score: compMetrics && compMetrics.metric_5.value,
      year: compMetrics && compMetrics.metric_5.date,
    },
    {
      metric: compMetrics && compMetrics.metric_6.title,
      desc1: compMetrics && compMetrics.metric_6.description_1,
      desc2: compMetrics && compMetrics.metric_6.description_2,
      score: compMetrics && compMetrics.metric_6.value,
      year: compMetrics && compMetrics.metric_6.date,
    },
  ]
  return (
    <>
      <Spacer size={theme.spacing.x4} />
      <Row>
        <Text
          tag="h1"
          size={[theme.fontScale.x5]}
          weight={theme.fontWeights.bold}
          lineHeight={theme.lineHeights.heading}
        >
          {`${selectedComp.selectedComp.competitionName} records`}
        </Text>
      </Row>
      {compMetrics && (
        <Row>
          <Col size={[1, 1 / 2]}>
            <Section>
              <>
                <Spacer size={theme.spacing.x3} />
                {FirstSet.map((id, idx) => (
                  <Fragment key={idx}>
                    <Row>
                      <div>
                        <Text
                          tag="h2"
                          size={[theme.fontScale.x5]}
                          weight={theme.fontWeights.bold}
                          lineHeight={theme.lineHeights.heading}
                        >
                          {id.metric}
                        </Text>
                        <Spacer size={theme.spacing.x1} />
                        <MetricsContainer>
                          <Metric>
                            <Text
                              tag="p"
                              size={[theme.fontScale.x6]}
                              weight={theme.fontWeights.normal}
                              lineHeight={theme.lineHeights.none}
                            >
                              {id.score}
                            </Text>
                          </Metric>
                          <Spacer size={theme.spacing.x2} />
                          <div>
                            <Text
                              tag="p"
                              size={[theme.fontScale.x2]}
                              weight={theme.fontWeights.normal}
                              lineHeight={theme.lineHeights.none}
                            >
                              {id.desc1}
                            </Text>
                            <Spacer size={theme.spacing.x1} />
                            <Text
                              tag="p"
                              size={[theme.fontScale.x2]}
                              weight={theme.fontWeights.normal}
                              lineHeight={theme.lineHeights.none}
                            >
                              {id.desc2}
                            </Text>
                          </div>
                          <Year>
                            <Text
                              tag="p"
                              size={[theme.fontScale.x2]}
                              weight={theme.fontWeights.normal}
                              lineHeight={theme.lineHeights.none}
                              align="right"
                            >
                              {id.year}
                            </Text>
                          </Year>
                        </MetricsContainer>
                      </div>
                    </Row>
                    <Spacer size={theme.spacing.x4} />
                  </Fragment>
                ))}
              </>
            </Section>
          </Col>
          <Col size={[1, 1 / 2]}>
            <Section>
              <>
                <Spacer size={theme.spacing.x3} />
                {SecondSet.map((id, idx) => (
                  <Fragment key={idx}>
                    <Row>
                      <div>
                        <Text
                          tag="h2"
                          size={[theme.fontScale.x5]}
                          weight={theme.fontWeights.bold}
                          lineHeight={theme.lineHeights.heading}
                        >
                          {id.metric}
                        </Text>
                        <Spacer size={theme.spacing.x1} />
                        <MetricsContainer>
                          <Metric>
                            <Text
                              tag="p"
                              size={[theme.fontScale.x6]}
                              weight={theme.fontWeights.normal}
                              lineHeight={theme.lineHeights.none}
                            >
                              {id.score}
                            </Text>
                          </Metric>
                          <Spacer size={theme.spacing.x2} />
                          <div>
                            <Text
                              tag="p"
                              size={[theme.fontScale.x2]}
                              weight={theme.fontWeights.normal}
                              lineHeight={theme.lineHeights.none}
                            >
                              {id.desc1}
                            </Text>
                            <Spacer size={theme.spacing.x1} />
                            <Text
                              tag="p"
                              size={[theme.fontScale.x2]}
                              weight={theme.fontWeights.normal}
                              lineHeight={theme.lineHeights.none}
                            >
                              {id.desc2}
                            </Text>
                          </div>
                          <Year>
                            <Text
                              tag="p"
                              size={[theme.fontScale.x2]}
                              weight={theme.fontWeights.normal}
                              lineHeight={theme.lineHeights.none}
                              align="right"
                            >
                              {id.year}
                            </Text>
                          </Year>
                        </MetricsContainer>
                      </div>
                    </Row>
                    <Spacer size={theme.spacing.x4} />
                  </Fragment>
                ))}{' '}
              </>
            </Section>
          </Col>
        </Row>
      )}

      <Spacer size={theme.spacing.x2} />
    </>
  )
}

export default VenueMetrics
