import React from 'react'

import theme from '../lib/theme'
import { formatText } from '../util/helperFunctions'

import Text from './Text'
import Spacer from './Spacer'
import Section from './Section'
import MetricsTable from './MetricsTable'
import { Row, Col } from './Grid'
import ResultsTable from './Results'

const OverallMetrics = ({ athlete, selectedData, selectedTab }) => {
  return (
    <>
      <Spacer size={theme.spacing.x2} />
      <Row>
        {selectedTab < 3 ? (
          <>
            <Col size={[1, 1 / 2]}>
              <Section
                title={`${athlete.athleteName} (${athlete.athleteNationality})`}
              >
                <>
                  <Spacer size={theme.spacing.x2} />
                  <MetricsTable data={selectedData.selectedRiderMetrics} />
                  <Spacer size={theme.spacing.x3} />
                </>
              </Section>
              <Spacer size={theme.spacing.x4} />
            </Col>
            <Col size={[1, 1 / 2]}>
              <Section title={`${athlete.horseName} (${athlete.horseGender})`}>
                <>
                  <Spacer size={theme.spacing.x2} />
                  <MetricsTable data={selectedData.selectedHorseMetrics} />
                  <Spacer size={theme.spacing.x3} />
                </>
              </Section>
            </Col>
          </>
        ) : (
          <>
            <Text
              tag="h3"
              size={[theme.fontScale.x5]}
              weight={theme.fontWeights.bold}
              lineHeight={theme.lineHeights.heading}
            >
              {selectedTab === 3
                ? formatText(`${athlete.athleteName} - CURRENT FORM`)
                : formatText(`${athlete.horseName} - CURRENT FORM`)}
            </Text>
            <ResultsTable
              selectedData={selectedData.selectedResultsCurrent}
              horseResults={selectedTab === 4}
            />
            <Spacer size={theme.spacing.x4} />
            <Text
              tag="h3"
              size={[theme.fontScale.x5]}
              weight={theme.fontWeights.bold}
              lineHeight={theme.lineHeights.heading}
            >
              {selectedTab === 3
                ? formatText(`${athlete.athleteName} - MAJOR RESULTS`)
                : formatText(`${athlete.horseName} - MAJOR RESULTS`)}
            </Text>
            <ResultsTable
              selectedData={selectedData.selectedResultsMajor}
              horseResults={selectedTab === 4}
            />
          </>
        )}
      </Row>
    </>
  )
}

export default OverallMetrics
