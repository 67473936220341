import {
  GET_COMPETITIONS_STARTED,
  GET_COMPETITIONS_SUCCESS,
  GET_COMPETITIONS_FAILURE,
  GET_ENTRIES_STARTED,
  GET_ENTRIES_SUCCESS,
  GET_ENTRIES_FAILURE,
  GET_RIDER_METRICS_STARTED,
  GET_RIDER_METRICS_SUCCESS,
  GET_RIDER_METRICS_FAILURE,
  GET_HORSE_METRICS_STARTED,
  GET_HORSE_METRICS_SUCCESS,
  GET_HORSE_METRICS_FAILURE,
  SET_COMPETITION,
  SET_ATHLETE,
  GET_PERSONAL_BESTS_STARTED,
  GET_PERSONAL_BESTS_SUCCESS,
  GET_PERSONAL_BESTS_FAILURE,
  GET_RIDER_RESULTS_STARTED,
  GET_RIDER_RESULTS_SUCCESS,
  GET_HORSE_RESULTS_STARTED,
  GET_HORSE_RESULTS_SUCCESS,
  GET_RIDER_RESULTS_FAILURE,
  GET_HORSE_RESULTS_FAILURE,
  CLEAR_METRICS,
} from './MetricsActions'
import { stat } from 'fs'

// INITIAL STATE //
const initialState = {
  loading: false,
  error: undefined,
  competitions: undefined,
  selectedComp: undefined,
  selectedAthlete: undefined,
  riderMetrics: undefined,
  personalBests: undefined,
  riderResults: undefined,
  horseResults: undefined,
}

// REDUCERS //
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPETITIONS_STARTED:
      return {
        ...state,
        loading: true,
      }
    case GET_COMPETITIONS_SUCCESS:
      return {
        ...state,
        competitions: action.payload.competitions,
        loading: false,
      }
    case GET_COMPETITIONS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_ENTRIES_STARTED:
      return {
        ...state,
        loading: true,
      }
    case GET_ENTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        entries: action.payload,
      }
    case GET_ENTRIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    case GET_RIDER_METRICS_STARTED:
      return {
        ...state,
        loading: true,
      }
    case GET_RIDER_METRICS_SUCCESS:
      return {
        ...state,
        riderMetrics: action.payload,
        loading: false,
      }
    case GET_RIDER_METRICS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case GET_HORSE_METRICS_STARTED:
      return {
        ...state,
        loading: true,
      }
    case GET_HORSE_METRICS_SUCCESS:
      return {
        ...state,
        horseMetrics: action.payload,
        loading: false,
      }
    case GET_HORSE_METRICS_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case GET_PERSONAL_BESTS_STARTED:
      return {
        ...state,
        loading: true,
      }
    case GET_PERSONAL_BESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        personalBests: action.payload,
      }
    case GET_PERSONAL_BESTS_FAILURE:
      return {
        ...stat,
        loading: false,
        error: action.payload,
      }
    case SET_COMPETITION:
      return {
        ...state,
        selectedComp: action.payload,
      }
    case SET_ATHLETE:
      return {
        ...state,
        selectedAthlete: action.payload,
      }
    case GET_RIDER_RESULTS_STARTED:
      return {
        ...state,
        loading: true,
      }
    case GET_RIDER_RESULTS_SUCCESS:
      return {
        ...state,
        loading: false,
        riderResults: {
          riderMajorResults: action.payload.riderMajorResults,
          riderCurrentForm: action.payload.riderCurrentForm,
        },
      }
    case GET_RIDER_RESULTS_FAILURE:
      return {
        ...state,
        error: action.payload,
      }
    case GET_HORSE_RESULTS_STARTED:
      return {
        ...state,
        loading: true,
      }
    case GET_HORSE_RESULTS_SUCCESS:
      return {
        ...state,
        horseResults: {
          horseMajorResults: action.payload.horseMajorResults,
          horseCurrentForm: action.payload.horseCurrentForm,
        },
      }
    case GET_HORSE_RESULTS_FAILURE:
      return {
        ...state,
        error: action.payload,
      }
    case CLEAR_METRICS:
      return {
        ...state,
        selectedAthlete: undefined,
        personalBests: undefined,
        riderMetrics: undefined,
        horseMetrics: undefined,
        riderResults: undefined,
        horseResults: undefined,
      }
    default:
      return state
  }
}
