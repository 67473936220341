import {
  getCompetitionsReq,
  setCompetition,
  getEntriesReq,
  setAthlete,
  getRiderMetricsReq,
  getHorseMetricsReq,
  getPersonalBestsReq,
  getRiderResultsReq,
  getHorseResultsReq,
  clearMetric,
} from './MetricsActions'

export const mapStateToProps = state => {
  return {
    competitions: state.MetricsState.competitions,
    loading: state.MetricsState.loading,
    error: state.MetricsState.error,
    entries: state.MetricsState.entries,
    selectedAthlete: state.MetricsState.selectedAthlete,
    selectedComp: state.MetricsState.selectedComp,
    isUserLoggedIn: state.UserState.isUserLoggedIn,
    accessToken: state.UserState.accessToken,
    personalBests: state.MetricsState.personalBests,
    riderMetrics: state.MetricsState.riderMetrics,
    horseMetrics: state.MetricsState.horseMetrics,
    timeOfLogin: state.UserState.timeOfLogin,
    riderResults: state.MetricsState.riderResults,
    horseResults: state.MetricsState.horseResults,
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    getCompetitions: () => dispatch(getCompetitionsReq()),
    setCompetition: (erLevel, venueName, compSourceId, venueId) =>
      dispatch(setCompetition(erLevel, venueName, compSourceId, venueId)),
    getEntries: compSourceId => dispatch(getEntriesReq(compSourceId)),
    setAthlete: athlete => dispatch(setAthlete(athlete)),
    getRiderMetrics: (erLevel, venueId, athleteId) =>
      dispatch(getRiderMetricsReq(erLevel, venueId, athleteId)),
    getHorseMetrics: (erLevel, venueId, horseId) =>
      dispatch(getHorseMetricsReq(erLevel, venueId, horseId)),
    getPersonalBests: (compSourceId, athleteId, horseId) =>
      dispatch(getPersonalBestsReq(compSourceId, athleteId, horseId)),
    getRiderResults: athleteId => dispatch(getRiderResultsReq(athleteId)),
    getHorseResults: horseId => dispatch(getHorseResultsReq(horseId)),
    clearMetric: () => dispatch(clearMetric()),
  }
}
