import React from 'react'
import styled from 'styled-components'
import Spacer from './Spacer'
import Section from './Section'
import theme from '../lib/theme.js'
import Text from './Text'

const Article = styled.article`
  background: ${theme.colors.secondaryLighter};
  border-radius: ${theme.radii[0]}px;
  padding-bottom: ${theme.spacing.x4};
  box-sizing: border-box;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }
`

const TextContainer = styled.div`
  padding-left: ${theme.spacing.x3};
  padding-right: ${theme.spacing.x3};
  box-sizing: border-box;
`

const Img = styled.img`
  width: 100%;
`

const BlogPost = ({ url, img, title, author }) => {
  return (
    <>
      <Section>
        <a href={url} target="_blank">
          <Article>
            <Img src={img} title={'Title'} alt={'Alternative'} />
            <TextContainer>
              <Spacer size={theme.spacing.x3} />
              <Text
                tag="h1"
                size={[theme.fontScale.x5]}
                color={theme.colors.primary}
                weight={theme.fontWeights.bold}
              >
                {title}
              </Text>
              <Spacer size={theme.spacing.x2} />
              <Text
                tag="h2"
                size={[theme.fontScale.x3]}
                color={theme.colors.white}
                weight={theme.fontWeights.bold}
              >
                {author}
              </Text>
            </TextContainer>
          </Article>
        </a>
      </Section>
    </>
  )
}

export default BlogPost
