import {
  getCompetitions,
  getEntries,
  riderStatsByDate,
  riderStatsByLevel,
  riderStatsAtComp,
  horseStatsByDate,
  horseStatsByLevel,
  horseStatsAtComp,
  getPersonalBests,
  getRiderResultsMr,
  getRiderResultsCf,
  getHorseResultsMr,
  getHorseResultsCf,
} from '../../util/metrics'

import { store } from '../store'

export const GET_COMPETITIONS_STARTED = 'GET_COMPETITIONS_STARTED'
export const GET_COMPETITIONS_SUCCESS = 'GET_COMPETITIONS_SUCCESS'
export const GET_COMPETITIONS_FAILURE = 'GET_COMPETITIONS_FAILURE'

export const GET_ENTRIES_STARTED = 'GET_ENTRIES_STARTED'
export const GET_ENTRIES_SUCCESS = 'GET_ENTIRES_SUCCESS'
export const GET_ENTRIES_FAILURE = 'GET_ENTIRES_FAILURE'

export const GET_RIDER_METRICS_STARTED = 'GET_RIDER_METRICS_STARTED'
export const GET_RIDER_METRICS_SUCCESS = 'GET_RIDER_METRICS_SUCCESS'
export const GET_RIDER_METRICS_FAILURE = 'GET_RIDER_METRICS_FAILURE'

export const GET_HORSE_METRICS_STARTED = 'GET_HORSE_METRICS_STARTED'
export const GET_HORSE_METRICS_SUCCESS = 'GET_HORSE_METRICS_SUCCESS'
export const GET_HORSE_METRICS_FAILURE = 'GET_HORSE_METRICS_FAILURE'

export const GET_PERSONAL_BESTS_STARTED = 'GET_PERSONAL_BESTS_STARTED'
export const GET_PERSONAL_BESTS_SUCCESS = 'GET_PERSONAL_BESTS_SUCCESS'
export const GET_PERSONAL_BESTS_FAILURE = 'GET_PERSONAL_BESTS_FAILURE'

export const GET_RIDER_RESULTS_STARTED = 'GET_RIDER_RESULTS_STARTED'
export const GET_RIDER_RESULTS_SUCCESS = 'GET_RIDER_RESULTS_SUCCESS'
export const GET_RIDER_RESULTS_FAILURE = 'GET_RIDER_RESULTS_FAILURE'

export const GET_HORSE_RESULTS_STARTED = 'GET_HORSE_RESULTS_STARTED'
export const GET_HORSE_RESULTS_SUCCESS = 'GET_HORSE_RESULTS_SUCCESS'
export const GET_HORSE_RESULTS_FAILURE = 'GET_HORSE_RESULTS_FAILURE'

export const SET_COMPETITION = 'SET_COMPETITION'
export const CLEAR_METRICS = 'CLEAR_METRICS'
export const SET_ATHLETE = 'SET_ATHLETE'

// ACTIONS //

//COMPETITIONS
export const getCompetitionsStarted = () => ({
  type: GET_COMPETITIONS_STARTED,
})
export const getCompetitionsSuccess = competitions => ({
  type: GET_COMPETITIONS_SUCCESS,
  payload: {
    competitions,
  },
})
export const getCompetitionsFailure = error => ({
  type: GET_COMPETITIONS_FAILURE,
  payload: error,
})

//ENTRIES
export const getEntriesStarted = () => ({
  type: GET_ENTRIES_STARTED,
})
export const getEntriesSuccess = athletes => ({
  type: GET_ENTRIES_SUCCESS,
  payload: athletes,
})
export const getEntriesFailure = error => ({
  type: GET_ENTRIES_FAILURE,
  payload: {
    error,
  },
})

//RIDER METRICS
export const getRiderMetricsStarted = () => ({
  type: GET_RIDER_METRICS_STARTED,
})
export const getRiderMetricsSuccess = (
  riderStatsAtDate,
  riderStatsAtLevel,
  riderStatsComp
) => ({
  type: GET_RIDER_METRICS_SUCCESS,
  payload: {
    riderStatsAtDate,
    riderStatsAtLevel,
    riderStatsComp,
  },
})
export const getRiderMetricsFailure = error => ({
  type: GET_RIDER_METRICS_FAILURE,
  payload: error,
})

//HORSE METRICS
export const getHorseMetricsStarted = () => ({
  type: GET_HORSE_METRICS_STARTED,
})
export const getHorseMetricsSuccess = (
  horseStatsAtDate,
  horseStatsAtLevel,
  horseStatsComp
) => ({
  type: GET_HORSE_METRICS_SUCCESS,
  payload: {
    horseStatsAtDate,
    horseStatsAtLevel,
    horseStatsComp,
  },
})
export const getHorseMetricsFailure = () => ({
  type: GET_HORSE_METRICS_FAILURE,
})

export const getPersonalBestsStarted = () => ({
  type: GET_PERSONAL_BESTS_STARTED,
})
export const getPersonalBestsSuccess = personalBests => ({
  type: GET_PERSONAL_BESTS_SUCCESS,
  payload: personalBests,
})
export const getPersonalBestsFailure = error => ({
  type: GET_COMPETITIONS_FAILURE,
  payload: error,
})

export const getRiderResultsStarted = () => ({
  type: GET_RIDER_RESULTS_STARTED,
})
export const getRiderResultsSuccess = (
  riderMajorResults,
  riderCurrentForm
) => ({
  type: GET_RIDER_RESULTS_SUCCESS,
  payload: {
    riderMajorResults,
    riderCurrentForm,
  },
})
export const getRiderResultsFailure = error => ({
  type: GET_RIDER_RESULTS_FAILURE,
  payload: error,
})

export const getHorseResultsStarted = () => ({
  type: GET_HORSE_RESULTS_STARTED,
})
export const getHorseResultsSuccess = (
  horseMajorResults,
  horseCurrentForm
) => ({
  type: GET_HORSE_RESULTS_SUCCESS,
  payload: {
    horseMajorResults,
    horseCurrentForm,
  },
})
export const getHorseResultsFailure = error => ({
  type: GET_HORSE_RESULTS_FAILURE,
  payload: error,
})

export const setCompetition = competition => ({
  type: SET_COMPETITION,
  payload: competition,
})

export const setAthlete = athlete => ({
  type: SET_ATHLETE,
  payload: athlete,
})

export const clearMetric = () => ({
  type: CLEAR_METRICS,
})

// ASYNC ACTION CREATORS //
export const getCompetitionsReq = () => {
  return dispatch => {
    dispatch(getCompetitionsStarted())
    getCompetitions()
      .then(response => {
        const competitions = response.data.data.map(item => {
          return {
            competition: item.attributes.venue_name,
            erLevel: item.attributes.er_level,
            compSourceId: item.attributes.competition_source_id,
            venueId: item.attributes.venue_id,
            competitionName: item.attributes.competition_name,
            className: item.attributes.class_name,
            competitionMetrics: item.attributes.competition_metrics,
            articles: item.attributes.articles,
          }
        })
        dispatch(getCompetitionsSuccess(competitions))
        dispatch(setCompetition(store.getState().MetricsState.competitions[0]))
        dispatch(
          getEntriesReq(
            store.getState().MetricsState.competitions[0].compSourceId
          )
        )
      })
      .catch(error => {
        dispatch(getCompetitionsFailure(error))
      })
  }
}

export const getEntriesReq = compSourceId => {
  return dispatch => {
    dispatch(getEntriesStarted())
    getEntries(compSourceId)
      .then(response => {
        const athletes = response.data.data.map(item => {
          return {
            athleteName: item.attributes.athlete,
            horseName: item.attributes.horse,
            athleteNationality: item.attributes.athlete_nationality,
            horseId: item.attributes.horse_id,
            athleteId: item.attributes.athlete_id,
            horseGender: item.attributes.horse_gender,
            withdrawn: item.attributes.withdrawn,
          }
        })
        dispatch(getEntriesSuccess(athletes))
      })
      .catch(error => {
        dispatch(getEntriesFailure(error))
      })
  }
}

export const getRiderMetricsReq = (erLevel, venueId, athleteId) => {
  return dispatch => {
    dispatch(getRiderMetricsStarted())
    Promise.all([
      riderStatsByDate(athleteId),
      riderStatsByLevel(erLevel, athleteId),
      riderStatsAtComp(erLevel, venueId, athleteId),
    ])
      .then(values => {
        const riderStatsAtDate = values[0].data.data[0].attributes
        const riderStatsAtLevel = values[1].data.data[0].attributes
        const riderStatsComp = values[2].data.data[0].attributes
        dispatch(
          getRiderMetricsSuccess(
            riderStatsAtDate,
            riderStatsAtLevel,
            riderStatsComp
          )
        )
      })
      .catch(error => dispatch(getRiderMetricsFailure(error)))
  }
}

export const getHorseMetricsReq = (erLevel, venueId, horseId) => {
  return dispatch => {
    dispatch(getHorseMetricsStarted())
    Promise.all([
      horseStatsByDate(horseId),
      horseStatsByLevel(erLevel, horseId),
      horseStatsAtComp(erLevel, venueId, horseId),
    ])
      .then(values => {
        const horseStatsAtDate = values[0].data.data[0].attributes
        const horseStatsAtLevel = values[1].data.data[0].attributes
        const horseStatsComp = values[2].data.data[0].attributes
        dispatch(
          getHorseMetricsSuccess(
            horseStatsAtDate,
            horseStatsAtLevel,
            horseStatsComp
          )
        )
      })
      .catch(error => dispatch(getHorseMetricsFailure(error)))
  }
}

export const getPersonalBestsReq = (compSourceId, athleteId, horseId) => {
  return dispatch => {
    dispatch(getPersonalBestsStarted())
    getPersonalBests(compSourceId, athleteId, horseId)
      .then(response => {
        const personalBests = response.data.data[0].attributes
        dispatch(getPersonalBestsSuccess(personalBests))
      })
      .catch(error => {
        dispatch(getPersonalBestsFailure(error))
      })
  }
}

export const getRiderResultsReq = athleteId => {
  return dispatch => {
    dispatch(getRiderResultsStarted())
    Promise.all([getRiderResultsMr(athleteId), getRiderResultsCf(athleteId)])
      .then(values => {
        const riderMajorResults = values[0].data.data
        const riderCurrentForm = values[1].data.data
        dispatch(getRiderResultsSuccess(riderMajorResults, riderCurrentForm))
      })
      .catch(error => {
        dispatch(getRiderResultsFailure(error))
      })
  }
}

export const getHorseResultsReq = horseId => {
  return dispatch => {
    dispatch(getHorseResultsStarted())
    Promise.all([getHorseResultsMr(horseId), getHorseResultsCf(horseId)])
      .then(values => {
        const horseMajorResults = values[0].data.data
        const horseCurrentForm = values[1].data.data
        dispatch(getHorseResultsSuccess(horseMajorResults, horseCurrentForm))
      })
      .catch(error => {
        dispatch(getHorseResultsFailure(error))
      })
  }
}
