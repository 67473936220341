import React from 'react'
import styled from 'styled-components'
import '../assets/loading.css'

const Spinner = () => (
  <div className="la-line-scale-wrapper">
    <div className="la-line-scale">
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
)

export default Spinner
