import React from 'react'

import theme from '../../lib/theme'

const AngleDown = ({ isOpen }) => (
  <svg width="20" height="13" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.913 12.48L.439 4.006a1.49 1.49 0 0 1 0-2.112L1.847.486a1.49 1.49 0 0 1 2.113 0l6.006 6.006L15.972.486a1.49 1.49 0 0 1 2.112 0l1.408 1.408a1.49 1.49 0 0 1 0 2.112L11.02 12.48a1.482 1.482 0 0 1-2.106 0z"
      fill={theme.colors.secondary}
      fillRule="nonzero"
      transform={isOpen ? 'rotate(180, 10, 6.3)' : ''}
    />
  </svg>
)

export default AngleDown
