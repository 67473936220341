/* This is a module containing helper functions that are used to
do things like parse the metrics or text so they display correctly etc. */

export function parseMetric(metric, percentageMetric, showDecimal) {
  if (metric === null) return '-'
  if (percentageMetric) {
    return `${parseFloat(percentageMetric * 100).toFixed(0)}%`
  } else if (metric && showDecimal) {
    return parseFloat(metric).toFixed(1)
  } else {
    return parseFloat(metric)
  }
}

export function formatText(string) {
  let splitString = string.toLowerCase().split(' ')
  const formattedString = splitString.map(
    item => (item = item.charAt().toUpperCase() + item.substring(1))
  )
  return formattedString.join(' ')
}

export const dateToYear = dateStr => (dateStr ? dateStr.split('-')[0] : '-')

export const round = (value, precision) => {
  if (value != null) { var multiplier = Math.pow(10, precision || 0)
    return (Math.round(value * multiplier) / multiplier).toFixed(precision)
  } else {
    return '-'
  }
}
