import React from 'react'
import styled from 'styled-components'

import theme from '../lib/theme'

import Text from '../components/Text'
import Spacer from '../components/Spacer'

const MetricsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Metric = styled.div`
  position: relative;
  top: 1px;
`

const Year = styled.div`
  align-self: flex-end;
  flex: 1;
  position: relative;
  bottom: 2px;
`

const PersonalBest = ({
  at,
  withHorse,
  score,
  horse,
  venue,
  klass,
  year,
}) => (
  <div>
    <Text
      tag="h2"
      size={[theme.fontScale.x4]}
      weight={theme.fontWeights.normal}
      lineHeight={theme.lineHeights.heading}
    >
      Best
      {withHorse ? ' with' : ''} <strong>{withHorse}</strong>
      {at ? ' at' : ''} <strong>{at}</strong>
    </Text>
    <Spacer size={theme.spacing.x1} />
    <MetricsContainer>
      <Metric>
        <Text
          tag="p"
          size={[theme.fontScale.x6]}
          weight={theme.fontWeights.bold}
          lineHeight={theme.lineHeights.none}
        >
          {score}
        </Text>
      </Metric>
      <Spacer size={theme.spacing.x2} />
      <div>
        <Text
          tag="p"
          size={[theme.fontScale.x2]}
          weight={theme.fontWeights.normal}
          lineHeight={theme.lineHeights.none}
        >
          {score !== '-' ? horse : ""}
        </Text>
        <Spacer size={theme.spacing.x1} />
        <Text
          tag="p"
          size={[theme.fontScale.x2]}
          weight={theme.fontWeights.normal}
          lineHeight={theme.lineHeights.none}
        >
          {score !== '-' ? `${venue} ${klass}` : ''}
        </Text>
      </div>
      <Year>
        <Text
          tag="p"
          size={[theme.fontScale.x2]}
          weight={theme.fontWeights.normal}
          lineHeight={theme.lineHeights.none}
          align="right"
        >
          {score !== '-' ? year : ""}
        </Text>
      </Year>
    </MetricsContainer>
  </div>
)

export default PersonalBest
