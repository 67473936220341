import React from 'react'
import styled from 'styled-components'
import Downshift from 'downshift'

import AngleDown from './icons/AngleDown'
import Box from './Box'

// TODO: Need to be broken out into seperate components, duping AthleteSelector
const Input = styled.input`
  border-radius: ${({ theme }) => theme.spacing.x2};
  border: none;
  font-size: ${({ theme, fontSize }) => fontSize || theme.fontScale.x4};
  font-weight: ${({ theme }) => theme.fontWeights.bold}
  color: ${({ theme }) => theme.colors.secondary};
  margin: 0;
  padding: ${({ theme }) => theme.spacing.x3};
  width: 100%;
`

// TODO: Need to be broken out into seperate components, duping AthleteSelector
const Relative = styled.div`
  position: relative;
  & > svg,
  & > button {
    position: absolute;
    right: ${({ theme }) => theme.spacing.x3};
    top: 50%;
    transform: translateY(-50%);
    -webkit-appearance: none;
    border: none;
    border-radius: 0;
    background: none;
    padding: 0;
    margin: 0;
    & > svg {
      position: relative;
      top: 1px;
    }
  }
`

// TODO: Need to be broken out into seperate components, duping AthleteSelector
const Suggestions = styled.div`
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border-radius: 0 4px;
  background: transparent;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  width: 100%;
`

// TODO: Need to be broken out into seperate components, duping AthleteSelector
const Suggestion = styled.div`
  background: ${({ highlighted, theme }) =>
    highlighted ? theme.colors.offWhite : theme.colors.white};
  font-weight: ${({ selected, theme }) =>
    selected ? theme.fontWeights.bold : theme.fontWeights.normal};
  padding: 0 ${({ theme }) => theme.spacing.x3};
  border-left: 2px solid
    ${({ highlighted, theme }) =>
      highlighted ? theme.colors.primary : theme.colors.white};
`

const Dropdown = ({
  onChange,
  items,
  itemToString,
  placeholder,
  handleFilter,
  displaySuggestion,
  initialItem,
  readOnly,
  fontSize,
}) =>
  console.log(fontSize) || (
    <Downshift
      onChange={onChange}
      itemToString={item => (item ? itemToString(item) : '')}
      initialSelectedItem={initialItem}
    >
      {({
        getRootProps,
        getInputProps,
        getItemProps,
        isOpen,
        highlightedIndex,
        toggleMenu,
        inputValue,
        selectedItem,
        initialSelectedItem,
      }) => (
        <div>
          <Relative>
            <button onClick={toggleMenu}>
              <AngleDown isOpen={isOpen} />
            </button>
            <Box>
              <Input
                type="text"
                fontSize={fontSize}
                placeholder={placeholder}
                onClick={toggleMenu}
                readOnly={readOnly}
                {...getInputProps()}
              />
            </Box>
            {isOpen && (
              <Suggestions>
                {items.filter(handleFilter(inputValue)).map((item, idx) => (
                  <Suggestion
                    {...getItemProps({
                      item,
                      key: idx,
                      highlighted: highlightedIndex === idx,
                      selected: selectedItem === item,
                    })}
                  >
                    {displaySuggestion(item)}
                  </Suggestion>
                ))}
              </Suggestions>
            )}
          </Relative>
        </div>
      )}
    </Downshift>
  )

export default Dropdown
