import React, { useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import theme from '../lib/theme'

import Text from '../components/Text'
import { Row } from '../components/Grid'
import Spacer from '../components/Spacer'
import Section from '../components/Section'
import Spinner from '../components/LoadingSpinner'
import { TextField, PasswordField, Submit } from '../components/Input'
import { userLogin } from '../redux/User/UserActions'

const Container = styled.div`
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
`

const Login = ({ userLogin, loginFailed, isLoading }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [hasErr, setHasErr] = useState(null)
  const errorMsg = 'Please check your email and password and try again.'

  const handleSubmit = e => {
    e.preventDefault()
    if (email && password) {
      userLogin(email, password)
    } else {
      setHasErr(errorMsg)
    }
  }
  if (loginFailed && !hasErr) setHasErr(errorMsg)
  return (
    <Container>
      <Spacer size={theme.spacing.x4} />
      {isLoading ? (
        <Spinner />
      ) : (
        <Row>
          <Text
            tag="h1"
            size={[theme.fontScale.x6]}
            weight={theme.fontWeights.bold}
            lineHeight={theme.lineHeights.heading}
          >
            Welcome to EquiRatings Media Centre
          </Text>
          <Spacer size={theme.spacing.x4} />
          <Section
            hasErr={hasErr !== null}
            title={hasErr ? hasErr : 'Please enter your details.'}
          >
            <form onSubmit={handleSubmit} method="post">
              <TextField
                onChange={e => setEmail(e.target.value)}
                placeholder="Email"
              />
              <PasswordField
                onChange={e => setPassword(e.target.value)}
                placeholder="Password"
              />
              <Submit value="Login" />
            </form>
          </Section>
        </Row>
      )}
    </Container>
  )
}

export default connect(
  ({ UserState }) => ({
    loginFailed: UserState.loginFailed,
    isLoading: UserState.loading,
  }),
  { userLogin }
)(Login)
