import React, { useEffect } from 'react'
import styled from 'styled-components'
import Downshift from 'downshift'
import { connect } from 'react-redux'

import theme from '../lib/theme'
import {
  mapStateToProps,
  mapDispatchToProps,
} from '../redux/Metrics/MetricsContainer'

import Box from './Box'
import Search from './icons/Search'
import Cancel from './icons/Cancel'
import Spacer from './Spacer'
import Text from './Text'

const Container = styled.div`
  margin-top: ${theme.spacing.x2};
  @media (${({ theme }) => theme.breakpoints.mediumAndUp}) {
    margin-top: 0;
  }
`
const Input = styled.input`
  border-radius: ${({ theme }) => theme.spacing.x2};
  border: none;
  font-size: ${({ theme }) => theme.fontScale.x5};
  margin: 0;
  padding: ${({ theme }) => theme.spacing.x3};
  width: 100%;
  color: ${({ theme }) => theme.colors.secondary};
`

const Button = styled.button``

const Relative = styled.div`
  position: relative;
  & > svg,
  & > button {
    position: absolute;
    right: ${({ theme }) => theme.spacing.x3};
    top: 50%;
    transform: translateY(-50%);
    -webkit-appearance: none;
    border: none;
    border-radius: 0;
    background: none;
    padding: 0;
    margin: 0;
    & > svg {
      position: relative;
      top: 1px;
    }
  }
`

const Suggestions = styled.div`
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border-radius: 0 4px;
  background: transparent;
  overflow: scroll;
  max-height: 66vh;
  padding: 0;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  width: 100%;
`

const Suggestion = styled.div`
  background: ${({ highlighted, theme }) =>
    highlighted ? theme.colors.offWhite : theme.colors.white};
  font-weight: ${({ selected, theme }) =>
    selected ? theme.fontWeights.bold : theme.fontWeights.normal};
  padding: 0 ${({ theme }) => theme.spacing.x3};
  border-left: 2px solid
    ${({ highlighted, theme }) =>
      highlighted ? theme.colors.primary : theme.colors.white};
`

const AthleteSelector = props => {
  const handleChange = item => {
    props.setAthlete(item)

    if (item) {
      props.getPersonalBests(
        props.selectedComp.compSourceId,
        item.athleteId,
        item.horseId
      )
      props.getRiderMetrics(
        props.selectedComp.erLevel,
        props.selectedComp.venueId,
        item.athleteId
      )
      props.getHorseMetrics(
        props.selectedComp.erLevel,
        props.selectedComp.venueId,
        item.horseId
      )
      props.getRiderResults(item.athleteId)
      props.getHorseResults(item.horseId)
    }
  }

  return (
    <Container>
      <Box>
        <Downshift
          onChange={(item, index) => {
            handleChange(item)
          }}
          itemToString={item =>
            item
              ? `${item.athleteName} (${item.athleteNationality}) - ${
                  item.horseName
                } (${item.horseGender})`
              : ''
          }
          initialSelectedItem={
            props.selectedAthlete ? props.selectedAthlete : null
          }
        >
          {({
            getInputProps,
            getItemProps,
            getLabelProps,
            isOpen,
            inputValue,
            highlightedIndex,
            selectedItem,
            toggleMenu,
            getRootProps,
            initialSelectedItem,
            clearSelection,
          }) => (
            <div {...getRootProps({}, { suppressRefError: true })}>
              <Relative>
                <Input
                  type="text"
                  onFocus={toggleMenu}
                  placeholder="Select an athlete / horse"
                  {...getInputProps()}
                  id="athleteSelector"
                />
                {isOpen && (
                  <Suggestions>
                    {props.entries
                      .filter(
                        item =>
                          !inputValue ||
                          `${item.athleteName} ${item.horseName}`
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                      )
                      .map((item, index) => (
                        <Suggestion
                          {...getItemProps({
                            key: index,
                            index,
                            item,
                            highlighted: highlightedIndex === index,
                            selected: selectedItem === item,
                          })}
                        >
                          <Spacer size={theme.spacing.x3} />
                          <Text
                            tag="h3"
                            size={[theme.fontScale.x4]}
                            weight={theme.fontWeights.bold}
                            lineHeight={theme.lineHeights.heading}
                          >
                            {item.athleteName}
                          </Text>
                          <Text
                            tag="h4"
                            size={[theme.fontScale.x3]}
                            lineHeight={theme.lineHeights.heading}
                          >
                            {item.horseName}
                          </Text>
                          {item.withdrawn ? (
                            <Text
                              tag="h4"
                              size={[theme.fontScale.x3]}
                              lineHeight={theme.lineHeights.heading}
                              color={'red'}
                            >
                              Withdrawn
                            </Text>
                          ) : null}
                          <Spacer size={theme.spacing.x3} />
                        </Suggestion>
                      ))}
                  </Suggestions>
                )}
                {inputValue === '' ? (
                  <Search />
                ) : (
                  <Button
                    id={'clearMetric'}
                    onClick={() => {
                      clearSelection()
                      props.clearMetric()
                    }}
                  >
                    <Cancel />
                  </Button>
                )}
              </Relative>
            </div>
          )}
        </Downshift>
      </Box>
    </Container>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AthleteSelector)
