import React from 'react'

import theme from '../../lib/theme'

const Cancel = () => (
  <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm5.062-9.794l-2.73-2.706 2.73-2.706a.501.501 0 0 0 0-.708l-1.648-1.648a.501.501 0 0 0-.708 0l-2.706 2.73-2.706-2.73a.501.501 0 0 0-.708 0L7.438 9.086a.501.501 0 0 0 0 .708l2.73 2.706-2.73 2.706a.501.501 0 0 0 0 .708l1.648 1.648a.501.501 0 0 0 .708 0l2.706-2.73 2.706 2.73a.501.501 0 0 0 .708 0l1.648-1.648a.501.501 0 0 0 0-.708z"
      fill={theme.colors.secondary}
      fillRule="evenodd"
    />
  </svg>
)

export default Cancel
