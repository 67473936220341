import React from 'react'

import theme from '../../lib/theme'

import SocialSvg from './SocialSvg'

const InstaIcon = ({
  width = theme.spacing.x4,
  color = theme.colors.secondary,
}) => (
  <SocialSvg
    width={width}
    viewBox="0 0 448 448"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M400 0c26.5 0 48 21.5 48 48v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h352zm-45.343 322.749c-5.826 14.64-17.105 25.92-31.82 31.82-22.036 8.74-74.324 6.723-98.675 6.723-24.35 0-76.713 1.942-98.674-6.722-14.64-5.827-25.92-17.106-31.82-31.821-8.74-22.035-6.723-74.323-6.723-98.674 0-24.351-1.942-76.713 6.723-98.674 5.826-14.64 17.105-25.92 31.82-31.82 22.036-8.74 74.323-6.723 98.674-6.723 24.351 0 76.714-1.942 98.674 6.722 14.64 5.827 25.92 17.106 31.821 31.821 8.74 22.035 6.723 74.323 6.723 98.674 0 24.351 2.017 76.713-6.723 98.674zm35.705-167.694c-1.27-26.816-7.395-50.569-27.04-70.14-19.57-19.57-43.324-25.695-70.14-27.04-27.638-1.568-110.476-1.568-138.114 0-26.741 1.27-50.495 7.395-70.14 26.966-19.645 19.57-25.695 43.324-27.04 70.14-1.569 27.637-1.569 110.476 0 138.114 1.27 26.816 7.395 50.569 27.04 70.14 19.645 19.57 43.324 25.695 70.14 27.04 27.638 1.568 110.476 1.568 138.114 0 26.816-1.27 50.57-7.395 70.14-27.04 19.57-19.571 25.695-43.324 27.04-70.14 1.569-27.638 1.569-110.402 0-138.04zm-56.844-20.317c0-11.055-8.964-20.019-20.019-20.019-11.055 0-20.018 8.964-20.018 20.02 0 11.054 8.889 20.018 20.018 20.018 11.055 0 20.019-8.89 20.019-20.019zM224.162 279.873c-30.7 0-55.798-25.023-55.798-55.798 0-30.775 25.023-55.798 55.798-55.798 30.775 0 55.799 25.023 55.799 55.798 0 30.775-25.098 55.798-55.799 55.798zm0-141.624c-47.507 0-85.826 38.32-85.826 85.826 0 47.507 38.32 85.826 85.826 85.826 47.507 0 85.827-38.32 85.827-85.826 0-47.507-38.32-85.826-85.827-85.826z"
      fill={color}
      fillRule="nonzero"
    />
  </SocialSvg>
)

export default InstaIcon
