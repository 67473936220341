import React from 'react'
import styled from 'styled-components'

const Space = styled.div`
  display: block;
  padding-top: ${({ size }) => size || 0};
  padding-left: ${({ size }) => size || 0};
`

const Spacer = ({ size }) => <Space size={size} />

export default Spacer
