import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import Logo from './icons/Logo'
import ScreenContainer from './ScreenContainer'
import Text from './Text'
import { clearMetric } from '../redux/Metrics/MetricsActions.js'
import theme from '../lib/theme'
import { persistor } from '../redux/store.js'

const HeaderBanner = styled.header`
  background: ${props => props.theme.colors.secondary};
  width: 100%;
`

const HeaderContainer = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: ${props => props.theme.spacing.x3};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const CTAContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`
const CTA = styled.div`
  padding: ${({ showBorder }) =>
    showBorder ? `0 ${theme.spacing.x3}` : `0  0 0  ${theme.spacing.x3}`}};
  border-right: ${({ showBorder }) =>
    showBorder ? `0.2px solid ${theme.colors.whiteTransparent}` : 'none'};
`

const handleLogout = () => {
  persistor.purge().then(() => document.location.reload())
}

const Header = ({ clearMetric, isUserLoggedIn, selectedAthlete }) => (
  <HeaderBanner role="banner">
    <HeaderContainer>
      <div onClick={() => clearMetric()} style={{ cursor: 'pointer' }}>
        <Logo />
      </div>
      {isUserLoggedIn && (
        <CTAContainer>
          <CTA showBorder={true} onClick={() => clearMetric()}>
            <Text
              tag="h1"
              size={[theme.fontScale.x3]}
              weight={theme.fontWeights.normal}
              color={theme.colors.white}
              lineHeight={theme.lineHeights.body}
              cursor={'pointer'}
            >
              Home
            </Text>
          </CTA>
          <CTA onClick={() => handleLogout()}>
            <Text
              tag="h1"
              size={[theme.fontScale.x3]}
              weight={theme.fontWeights.normal}
              color={theme.colors.white}
              lineHeight={theme.lineHeights.body}
              cursor={'pointer'}
            >
              Logout
            </Text>
          </CTA>
        </CTAContainer>
      )}
    </HeaderContainer>
  </HeaderBanner>
)

export default connect(
  ({ MetricsState, UserState }) => ({
    isUserLoggedIn: UserState.isUserLoggedIn,
  }),
  { clearMetric }
)(Header)
