import React from 'react'
import styled from 'styled-components'

import theme from '../lib/theme'

import Text from './Text'
import Spacer from './Spacer'
import InstaIcon from './icons/InstagramSVG'
import FacebookIcon from './icons/FacebookSVG'
import TwitterIcon from './icons/TwitterSVG'

const Container = styled.div`
  width: 100%;
  background-color: ${theme.colors.offWhite};
  padding: ${theme.spacing.x2} ${theme.spacing.x2};
  display: flex;
  box-sizing: border-box;
  max-width: 1440px;
  margin: 0 auto;
  justify-content: space-between;
`
const LogoAndRights = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
`

const SocialsAndLinks = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const PDF = require('../assets/BrandGuidelines.pdf')

const Footer = () => {
  return (
    <>
      <Container>
        <LogoAndRights>
          <a href="https://www.equiratings.com" target="_blank">
            <img
              src="https://www.datocms-assets.com/6892/1549884796-logo-yellow.png"
              alt="EquiRatings Logo"
              height="50px"
              width="50px"
              style={{ marginRight: '15px' }}
            />
          </a>
          <Text
            tag="h1"
            size={[theme.fontScale.x2]}
            weight={theme.fontWeights.normal}
            color={theme.colors.secondary}
            lineHeight={theme.lineHeights.body}
          >
            @2019 all rights reserved.
          </Text>
        </LogoAndRights>
        <SocialsAndLinks>
          <>
            <a href="https://www.instagram.com/equiratings/" target="_blank">
              <InstaIcon />
            </a>
            <a href="https://www.facebook.com/EquiRatings/" target="_blank">
              <FacebookIcon />
            </a>
            <a
              href="https://twitter.com/equiratings"
              target="_blank"
              style={{ marginRight: '10px' }}
            >
              <TwitterIcon />
            </a>
            <a href={PDF} target="_blank">
              <Text
                tag="h1"
                size={[theme.fontScale.x2]}
                weight={theme.fontWeights.normal}
                color={theme.colors.secondary}
                lineHeight={theme.lineHeights.body}
                cursor={'pointer'}
              >
                Our Brand Guidelines and Logos.
              </Text>
            </a>
          </>
        </SocialsAndLinks>
      </Container>
    </>
  )
}

export default Footer
