import React, { useEffect } from 'react'
import styled from 'styled-components'

import theme from '../lib/theme'
import { persistor } from '../redux/store'

import { Row, Col } from '../components/Grid'
import Spacer from '../components/Spacer'
import Text from '../components/Text'
import { Submit, TextField } from '../components/Input'

const Container = styled.div`
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
`

const handleInvalidToken = () => {
  persistor.purge().then(() => {
    document.location.reload()
  })
}

const FailedAccess = ({ accessToken }) => {
  return (
    <Container>
      <Spacer size={theme.spacing.x4} />
      {!accessToken ? (
        <Row>
          <Text
            tag="h1"
            size={[theme.fontScale.x6]}
            weight={theme.fontWeights.bold}
            lineHeight={theme.lineHeights.heading}
          >
            Your session has expired, please log in again.
          </Text>
          <Spacer size={theme.spacing.x4} />
          <Submit value="Login" onClick={() => handleInvalidToken()} />
        </Row>
      ) : (
        <Row>
          <Text
            tag="h1"
            size={[theme.fontScale.x6]}
            weight={theme.fontWeights.bold}
            lineHeight={theme.lineHeights.heading}
          >
            Your account currently doesn't have access to any ongoing
            competitions.
          </Text>{' '}
          <Spacer size={theme.spacing.x4} />
          <Text
            tag="h1"
            size={[theme.fontScale.x5]}
            weight={theme.fontWeights.normal}
            lineHeight={theme.lineHeights.heading}
          >
            Please contact EquiRatings to gain access.
          </Text>
          <Spacer size={theme.spacing.x4} />
          <form method="POST">
            <input type="hidden" name="form-name" value="contact" />
            <TextField placeholder="Name" type="text" name="name" />
            <TextField placeholder="Email" type="email" name="email" />
            <TextField
              placeholder="Organization"
              type="text"
              name="organization"
            />
            <Submit value="Send" />
          </form>
        </Row>
      )}
    </Container>
  )
}

export default FailedAccess
