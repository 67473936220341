import React from 'react'

import { Table, Th, Td, Tr } from './Table'
import { round, parseMetric } from '../util/helperFunctions'

const MetricsTable = ({ data }) => {
  return (
    <>
      <Table>
        <thead />
        <tbody>
          <Tr>
            <Td>Runs</Td>
            <Td>{round(data.count_runs, 0)}</Td>
          </Tr>
          <Tr>
            <Td>Completions</Td>
            <Td>
              {`${round(data.count_completions, 0)}/${round(
                data.completion_rate * 100,
                0
              )}%`}
            </Td>
          </Tr>
          <Tr>
            <Td>Wins</Td>
            <Td>{`${round(data.count_wins, 0)}/${round(
              data.win_rate * 100,
              0
            )}%`}</Td>
          </Tr>
          <Tr>
            <Td>Top 5's</Td>
            <Td>{`${round(data.count_top_5s, 0)}/${round(
              data.top_5_rate * 100,
              0
            )}%`}</Td>
          </Tr>
          <Tr>
            <Td>Average Dressage</Td>
            <Td>{round(data.dr_score_new_average, 1)}</Td>
          </Tr>
          <Tr>
            <Td>XCJ Clear</Td>
            <Td>{`${round(data.count_xcj_clears, 0)}/${round(
              data.xcj_clear_rate * 100,
              0
            )}%`}</Td>
          </Tr>
          <Tr>
            <Td>XCT Clear</Td>
            <Td>{`${round(data.count_xct_clears, 0)}/${round(
              data.xct_clear_rate * 100,
              0
            )}%`}</Td>
          </Tr>
          <Tr>
            <Td>XCT Average</Td>
            <Td>{round(data.xct_average, 1)}</Td>
          </Tr>
          <Tr>
            <Td>SJ Clear</Td>
            <Td>{`${round(data.count_sj_clears, 0)}/${round(
              data.sj_clear_rate * 100,
              0
            )}%`}</Td>
          </Tr>
          <Tr>
            <Td>SJJ Average</Td>
            <Td>{round(data.sjj_average, 1)}</Td>
          </Tr>
          <Tr>
            <Td>Average final score</Td>
            <Td>{round(data.final_score_average, 1)}</Td>
          </Tr>
        </tbody>
      </Table>
    </>
  )
}

export default MetricsTable
