import React, { useState } from 'react'
import styled from 'styled-components'
import { format, subYears } from 'date-fns'

import theme from '../lib/theme'

import Text from './Text'
import { Row, Col } from './Grid'
import Spacer from './Spacer'
import OverallMetrics from './OverallMetrics'
import Dropdown from './Dropdown'
import PersonalBestMetrics from './PersonalBestMetrics'
import Spinner from './LoadingSpinner'

const Metrics = ({
  athlete,
  personalBests,
  competition,
  riderMetrics,
  horseMetrics,
  riderResults,
  horseResults,
}) => {
  const showMetrics =
    personalBests &&
    riderMetrics &&
    horseMetrics &&
    riderResults &&
    horseResults

  const metricsSets = [
    { title: 'Last 12 months', activeTab: 0 },
    { title: `At this level since 2008`, activeTab: 1 },
    { title: `At ${competition.competitionName}`, activeTab: 2 },
    { title: 'Recent rider results', activeTab: 3 },
    { title: 'Recent horse results', activeTab: 4 },
  ]

  const [activeTab, setActiveTab] = useState(
    parseInt(localStorage.getItem('activeTab'), 10) || 0
  )

  const setMetric = metric => {
    localStorage.setItem('activeTab', metric)
    setActiveTab(metric)
  }

  let selectedHorseMetrics,
    selectedRiderMetrics,
    selectedResultsMajor,
    selectedResultsCurrent

  if (showMetrics) {
    switch (activeTab) {
      case 1:
        selectedHorseMetrics = horseMetrics.horseStatsAtLevel
        selectedRiderMetrics = riderMetrics.riderStatsAtLevel
        break
      case 2:
        selectedHorseMetrics = horseMetrics.horseStatsComp
        selectedRiderMetrics = riderMetrics.riderStatsComp
        break
      case 3:
        selectedResultsMajor = riderResults.riderMajorResults
        selectedResultsCurrent = riderResults.riderCurrentForm
        break
      case 4:
        selectedResultsMajor = horseResults.horseMajorResults
        selectedResultsCurrent = horseResults.horseCurrentForm
        break
      default:
        selectedHorseMetrics = horseMetrics.horseStatsAtDate
        selectedRiderMetrics = riderMetrics.riderStatsAtDate
        break
    }
  }

  const selectedData = {
    selectedHorseMetrics,
    selectedRiderMetrics,
    selectedResultsMajor,
    selectedResultsCurrent,
  }
  return showMetrics ? (
    <>
      <Spacer size={theme.spacing.x4} />
      <PersonalBestMetrics
        athlete={athlete}
        personalBests={personalBests}
        competition={competition}
      />
      <Row>
        <Spacer size={theme.spacing.x3} />
        <Text
          tag="h1"
          size={[theme.fontScale.x6]}
          weight={theme.fontWeights.bold}
          lineHeight={theme.lineHeights.heading}
        >
          Select date range
        </Text>
        <Spacer size={theme.spacing.x3} />
      </Row>
      <Row>
        <Col size={[1, 1 / 2]}>
          <Dropdown
            items={metricsSets}
            onChange={item => setMetric(item.activeTab)}
            itemToString={item => item.title}
            placeholder="Choose metric type"
            initialItem={metricsSets[activeTab]}
            handleFilter={() => () => true}
            readOnly={true}
            displaySuggestion={item => (
              <>
                <Spacer size={theme.spacing.x3} />
                <Text
                  tag="h3"
                  size={[theme.fontScale.x4]}
                  weight={theme.fontWeights.bold}
                  lineHeight={theme.lineHeights.heading}
                >
                  {item.title}
                </Text>
                <Spacer size={theme.spacing.x3} />
              </>
            )}
          />
        </Col>
      </Row>
      <Spacer size={theme.spacing.x4} />
      <OverallMetrics
        athlete={athlete}
        selectedData={selectedData}
        selectedTab={activeTab}
      />
      <Spacer size={theme.spacing.x4} />
    </>
  ) : (
    <>
      <Spacer size={theme.spacing.x6} />
      <Spinner />
    </>
  )
}
export default Metrics
