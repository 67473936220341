import React from 'react'

import theme from '../../lib/theme'

const Search = () => (
  <svg width="24" height="25" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.674 21.36L19 16.55a1.108 1.108 0 0 0-.797-.338h-.764a10.174 10.174 0 0 0 2.062-6.176C19.501 4.492 15.137 0 9.751 0 4.364 0 0 4.492 0 10.036s4.364 10.036 9.75 10.036a9.53 9.53 0 0 0 6.001-2.123v.786c0 .309.117.603.328.82l4.674 4.81c.44.454 1.153.454 1.59 0L23.668 23c.44-.453.44-1.187.005-1.64zM9.75 16.212c-3.315 0-6-2.76-6-6.176 0-3.411 2.68-6.176 6-6.176 3.314 0 6 2.76 6 6.176 0 3.411-2.681 6.176-6 6.176z"
      fill={theme.colors.secondary}
      fillRule="nonzero"
    />
  </svg>
)

export default Search
