import {
  USER_LOGIN_STARTED,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT,
  CREATE_NOTIFICATION,
} from './UserActions'

// INITIAL STATE //
const initialState = {
  loading: false,
  accessToken: undefined,
  refreshToken: undefined,
  error: undefined,
  isUserLoggedIn: undefined,
  user: undefined,
  timeOfLogin: undefined,
  loginFailed: false,
  activeCompetition: undefined,
  showNotification: undefined,
  notificationMessage: undefined,
}

// REDUCERS //

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_STARTED:
      return {
        ...state,
        loading: true,
      }
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        accessToken: action.payload.data.data.attributes.access_token,
        refreshToken: action.payload.data.data.attributes.refresh_token,
        loading: false,
        isUserLoggedIn: true,
        loginFailed: false,
        timeOfLogin: action.payload.date,
        activeCompetition:
          action.payload.data.data.attributes.active_competition,
      }
    case USER_LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        isUserLoggedIn: false,
        loginFailed: true,
      }
    case USER_LOGOUT:
      return {
        ...state,
        isUserLoggedIn: false,
        user: undefined,
        accessToken: undefined,
      }
    case CREATE_NOTIFICATION:
      return {
        ...state,
        showNotification: true,
        notificationMessage: action.payload,
      }
    default:
      return state
  }
}
