import React from 'react'

import theme from '../lib/theme'

import Text from '../components/Text'
import Spacer from '../components/Spacer'
import Box from '../components/Box'

const Section = ({ title, children, hasErr=false }) => (
  <section>
    <Text
      tag="h1"
      size={[theme.fontScale.x5]}
      weight={theme.fontWeights.bold}
      lineHeight={theme.lineHeights.heading}
      color={hasErr ? theme.colors.error : theme.colors.secondary}
    >
      {title}
    </Text>
    <Spacer size={theme.spacing.x2} />
    <Box>{children}</Box>
  </section>
)

export default Section
