import { userLoginRequest } from '../../util/user'
// import { getCompetitionsReq } from "../Metrics/MetricsActions";

// ACTION CREATORS //
export const USER_LOGIN_STARTED = 'USER_LOGIN_STARTED'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE'
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION'

export const USER_LOGOUT = 'USER_LOGOUT'

// ACTIONS //
export const userLoginStarted = () => ({
  type: USER_LOGIN_STARTED,
})

export const userLoginSuccess = (data, email, date) => ({
  type: USER_LOGIN_SUCCESS,
  payload: { data, email, date },
})

export const userLoginFailure = error => ({
  type: USER_LOGIN_FAILURE,
  payload: error,
})

export const userLogOut = () => ({
  type: USER_LOGOUT,
})

export const createNotification = notificationString => ({
  type: CREATE_NOTIFICATION,
  payload: notificationString,
})

// ASYNC ACTIONS //
export const userLogin = (email, password) => {
  return dispatch => {
    dispatch(userLoginStarted())
    userLoginRequest(email, password)
      .then(response => {
        dispatch(userLoginSuccess(response.data, email, Date.now()))
      })
      .catch(error => {
        dispatch(userLoginFailure(error))
      })
  }
}
