import styled, { css } from 'styled-components'

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
`

const cellStyle = css`
  text-align: left;
  font-family: ${({ theme }) => theme.fontFamily.sanSerif};
  padding: ${({ theme }) => `${theme.spacing.x3} ${theme.spacing.x3}`};
  color: ${({ theme }) => theme.colors.secondary};
  line-height: 1.25;
`

export const Th = styled.th`
  ${cellStyle}
  font-size: ${({ theme }) => theme.fontScale.x4};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`
// TODO: Find a better solution tr in thead and Tr in tbody
export const Tr = styled.tr`
  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.stripe};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryTransparent};
  }
`
export const Td = styled.td`
  ${cellStyle}
  font-weight: ${({ isBold, theme }) =>
    isBold ? theme.fontWeights.bold : theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontScale.x3};
`
