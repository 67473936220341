import styled from 'styled-components'

import theme from '../lib/theme'

const base = `
  background: transparent;
  border: none;
  border-bottom: 1px solid ${theme.colors.lightGrey};
  border-radius: 4px 4px 0 0;
  color: ${theme.colors.secondary};
  width: 100%;
  font-size: ${theme.fontScale.x4};
  padding: ${theme.spacing.x3};
  margin: 0;
`

const Input = styled.input.attrs({
  type: 'text',
})`
  ${base}
  ${({ last }) => (last ? 'border-color: transparent;' : null)}
`

export const TextField = Input 

export const PasswordField = styled.input.attrs({
  type: 'password',
})`
  ${base}
  ${({ last }) => (last ? 'border-color: transparent;' : null)}
`

export const Submit = styled.input.attrs({
  type: 'submit',
})`
  ${base}
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border: none;
  border-radius: 0 0 4px 4px;
  background: ${theme.colors.primary};
  font-weight: ${theme.fontWeights.bold};
`

export default Input
