import styled from 'styled-components'
import theme from '../lib/theme'

export const Row = styled.div`
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacing.x3};
  &:after {
    content: '';
    display: table;
    clear: both;
  }
`

export const Col = styled.div`
  float: left;
  width: ${({ size }) => `${size[0] * 100}%`};
  box-sizing: border-box;
  padding-right: ${({ theme, size }) => (size[0] === 1 ? 0 : theme.spacing.x3)};

  &:last-child {
    padding-right: 0;
  }

  padding-bottom: 0;
  @media (${({ theme }) => theme.breakpoints.mediumAndUp}) {
    width: ${({ size }) => `${size[1] * 100}%`};
    padding-right: ${({ theme, size }) =>
      size[1] === 1 ? 0 : theme.spacing.x3};
  }
`

export default {
  Col,
  Row,
}
