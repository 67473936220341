import React from 'react'
import styled from 'styled-components'

import { Table, Th, Td, Tr } from './Table'
import { parseMetric, formatText } from '../util/helperFunctions'
import theme from '../lib/theme'
import Spacer from './Spacer'
import Section from './Section'
import { format } from 'date-fns'

const ShowSmall = styled.div`
  display: none;
  @media only screen and (${theme.breakpoints.mediumAndUp}) {
    display: block;
  }
`
const ShowLarge = styled.div`
  display: block;
  @media only screen and (${theme.breakpoints.large}) {
    display: none;
  }
`

const ResultsTable = ({ selectedData, horseResults }) => {
  return (
    console.log(horseResults) || (
      <div>
        <Section>
          <ShowSmall>
            <Table>
              <tbody>
                <tr>
                  <Th>Date</Th>
                  <Th>Event</Th>
                  <Th>{horseResults ? 'Rider' : 'Horse'}</Th>
                  <Th>Class</Th>
                  <Th>DR</Th>
                  <Th>XCJ/XCT</Th>
                  <Th>SJJ/SJT</Th>
                  <Th>Total</Th>
                  <Th>Place</Th>
                </tr>
                {selectedData.slice(0, 10).map((row, i) => (
                  <Tr key={i}>
                    <Td>
                      {format(row.attributes.competition_date, 'DD/MM/YYYY')}
                    </Td>
                    <Td>{row.attributes.venue}</Td>
                    <Td>
                      {horseResults
                        ? formatText(row.attributes.athlete)
                        : formatText(row.attributes.horse)}
                    </Td>
                    <Td>{row.attributes.class}</Td>
                    <Td>
                      {parseMetric(row.attributes.dr_score_new, null, false)}
                    </Td>
                    <Td>
                      {`${parseMetric(
                        row.attributes.xc_jump,
                        null,
                        0
                      )}/${parseMetric(row.attributes.xc_time, null, 1)}`}
                    </Td>
                    <Td>
                      {`${parseMetric(
                        row.attributes.sj_jump,
                        null,
                        0
                      )}/${parseMetric(row.attributes.sj_time_new, null, 0)}`}
                    </Td>
                    <Td>
                      {parseMetric(row.attributes.final_score_new, null, 1)}
                    </Td>
                    <Td>
                      {parseMetric(row.attributes.final_position, null, 0)}
                    </Td>
                  </Tr>
                ))}
              </tbody>
            </Table>
          </ShowSmall>
        </Section>
        <ShowLarge>
          {selectedData.slice(0, 10).map((row, i) => (
            <Section key={i}>
              <Spacer size={theme.spacing.x3} />
              <Table>
                <tbody>
                  <Tr>
                    <Td isBold={true}>Date</Td>
                    <Td>{row.attributes.competition_date}</Td>
                  </Tr>
                  <Tr>
                    <Td isBold={true}>Event</Td>
                    <Td>{row.attributes.venue}</Td>
                  </Tr>
                  <Tr>
                    <Td isBold={true}>Horse</Td>
                    <Td>{formatText(row.attributes.horse)}</Td>
                  </Tr>
                  <Tr>
                    <Td isBold={true}>Class</Td>
                    <Td>{row.attributes.class}</Td>
                  </Tr>
                  <Tr>
                    <Td isBold={true}>DR</Td>
                    <Td>
                      {parseMetric(row.attributes.dr_score_new, null, false)}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td isBold={true}>XJC/XCT</Td>
                    <Td>{`${parseMetric(
                      row.attributes.xc_jump,
                      null,
                      0
                    )}/${parseMetric(row.attributes.xc_time, null, 1)}`}</Td>
                  </Tr>
                  <Tr>
                    <Td isBold={true}>SJJ/SJT</Td>
                    <Td>
                      {`${parseMetric(
                        row.attributes.sj_jump,
                        null,
                        0
                      )}/${parseMetric(row.attributes.sj_time_new, null, 0)}`}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td isBold={true}>Total</Td>
                    <Td>
                      {parseMetric(row.attributes.final_score_new, null, 1)}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td isBold={true}>Place</Td>
                    <Td>
                      {parseMetric(row.attributes.final_position, null, 0)}
                    </Td>
                  </Tr>
                </tbody>
              </Table>
            </Section>
          ))}
        </ShowLarge>
      </div>
    )
  )
}
export default ResultsTable
