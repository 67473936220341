import React, { Fragment } from 'react'

import theme from '../lib/theme'
import { dateToYear, round } from '../util/helperFunctions'

import Text from './Text'
import { Row, Col } from './Grid'
import Spacer from './Spacer'
import Section from './Section'
import PersonalBest from './PersonalBest'

const PersonalBestSection = ({ title, bwhaldr, bwh, bal, bav }) => (
  <>
    <Section title={title}>
      <Row>
        {[bwhaldr, bwh, bal, bav].map((metric, idx) => (
          <Fragment key={idx}>
            <Spacer size={theme.spacing.x3} />
            <PersonalBest
              withHorse={metric.with}
              at={metric.at}
              score={metric.score}
              horse={metric.horse}
              venue={metric.venue}
              klass={metric.klass}
              year={metric.year}
            />
            <Spacer size={theme.spacing.x1} />
          </Fragment>
        ))}
      </Row>
      <Spacer size={theme.spacing.x3} />
    </Section>
    <Spacer size={theme.spacing.x4} />
  </>
)

const PersonalBestMetrics = ({ athlete, personalBests, competition }) => (
  <>
    <Row>
      <Text
        tag="h1"
        size={[theme.fontScale.x6]}
        weight={theme.fontWeights.bold}
        lineHeight={theme.lineHeights.heading}
      >
        Personal Bests
      </Text>
    </Row>
    <Spacer size={theme.spacing.x2} />
    <Row>
      <Col size={[1, 1 / 2]}>
        <PersonalBestSection
          title="Dressage"
          bwhaldr={{
            with: athlete.horseName,
            at: competition.className,
            score: round(personalBests.bwhaldr_dr_score_new, 1),
            horse: athlete.horseName,
            venue: personalBests.bwhaldr_venue,
            klass: personalBests.bwhaldr_class_name,
            year: dateToYear(personalBests.bwhaldr_date),
          }}
          bwh={{
            with: athlete.horseName,
            score: round(personalBests.bwhdr_dr_score_new, 1),
            horse: athlete.horseName,
            venue: personalBests.bwhdr_venue,
            klass: personalBests.bwhdr_class_name,
            year: dateToYear(personalBests.bwhdr_date),
          }}
          bal={{
            at: competition.className,
            score: round(personalBests.baldr_dr_score_new, 1),
            horse: personalBests.baldr_horse,
            venue: personalBests.baldr_venue,
            klass: competition.className,
            year: dateToYear(personalBests.baldr_date),
          }}
          bav={{
            at: competition.competitionName,
            score: round(personalBests.bavdr_dr_score_new, 1),
            horse: personalBests.bavdr_horse,
            venue: personalBests.bavdr_venue || competition.competition,
            klass: competition.className,
            year: dateToYear(personalBests.bavdr_date),
          }}
        />
      </Col>
      <Col size={[1, 1 / 2]}>
        <PersonalBestSection
          title="Finishing Score"
          bwhaldr={{
            with: athlete.horseName,
            at: competition.className,
            score: round(personalBests.bwhalfs_final_score_new, 1),
            horse: athlete.horseName,
            venue: personalBests.bwhalfs_venue,
            klass: personalBests.bwhalfs_class_name,
            year: dateToYear(personalBests.bwhalfs_date),
          }}
          bwh={{
            with: athlete.horseName,
            score: round(personalBests.bwhfs_final_score_new, 1),
            horse: athlete.horseName,
            venue: personalBests.bwhfs_venue,
            klass: personalBests.bwhfs_class_name,
            year: dateToYear(personalBests.bwhfs_date),
          }}
          bal={{
            at: competition.className,
            score: round(personalBests.balfs_final_score_new, 1),
            horse: personalBests.balfs_horse,
            venue: personalBests.balfs_venue,
            klass: competition.className,
            year: dateToYear(personalBests.balfs_date),
          }}
          bav={{
            at: competition.competitionName,
            score: round(personalBests.bavfs_final_score_new, 1),
            horse: personalBests.bavfs_horse,
            venue: personalBests.bavfs_venue || competition.competition,
            klass: competition.className,
            year: dateToYear(personalBests.bavfs_date),
          }}
        />
      </Col>
    </Row>
  </>
)

export default PersonalBestMetrics
