import React from 'react'
import { createGlobalStyle } from 'styled-components'

import Header from './Header'
import Footer from './Footer'

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    margin: 0;
    padding: 0;
    background: ${props => props.theme.colors.offWhite};
  }
`

const Layout = ({ embed, children, isLoggedIn }) => (
  <>
    <GlobalStyle />
    <Header />
    <main role="main">{children}</main>
    {isLoggedIn ? <Footer /> : null}
  </>
)

export default Layout
