import React from 'react'
import { connect } from 'react-redux'
import { differenceInDays } from 'date-fns'

import Layout from './Layout'
import Home from '../screens/Home'
import Login from '../screens/Login'
import FailedAccess from './FailedAccess'

const Router = ({ isLoggedIn, activeCompetition, timeOfLogin }) => {
  const isAccessTokenValid = differenceInDays(timeOfLogin, Date.now()) < 1
  return (
    <Layout isLoggedIn={isLoggedIn}>
      {isLoggedIn ? (
        <>
          {activeCompetition != null && isAccessTokenValid ? (
            <Home />
          ) : (
            <FailedAccess accessToken={isAccessTokenValid} />
          )}
        </>
      ) : (
        <>
          <Login />
        </>
      )}
    </Layout>
  )
}

export default connect(({ UserState, MetricsState }) => ({
  isLoggedIn: UserState.isUserLoggedIn,
  activeCompetition: UserState.activeCompetition,
  timeOfLogin: UserState.timeOfLogin,
}))(Router)
